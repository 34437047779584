@use '../../../../app/styles/fonts';
.ChatMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: inherit;

  &__Messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 48px 32px;
    overflow: auto;
  }
}

.ChatMessage {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  &__Avatar {
    cursor: pointer;
    border-radius: 100px;
    object-fit: cover;
    min-width: 44px;
    width: 44px;
    height: 44px;

    &_system {
      padding: 4px;
      background-color: var(--white);
    }
  }

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__Name {
    display: flex;
    align-items: center;
    gap: 8px;
    @include fonts.inter_16_medium;
  }

  &__Time {
    @include fonts.inter_14_regular;
    color: var(--grey);
  }

  &__Text {
    white-space: normal;
    @include fonts.inter_14_regular;
  }
}

.ChatInput {
  display: flex;
  align-items: center;
  gap: 12px;

  border-top: 1px solid #d1d2d6;
  padding: 16px;

  &__Clip,
  &__Send {
    align-self: flex-end;
    cursor: pointer;
    height: 24px;
    border: 0;
    background-color: unset;
  }

  &__Field {
    resize: none;
    max-height: 200px;
    width: 100%;
    @include fonts.inter_16_regular;
    border: 0;
    outline: 0;
    background-color: unset;

    &::placeholder {
      color: var(--grey);
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
