@use '../../../../app/styles/fonts';

.ChatList {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.Search {
  margin: 12px 16px 16px;
  border-radius: 24px;

  & > label {
    border-radius: 24px;
  }
}

.ChatItem {
  max-width: 370px;

  position: relative;
  cursor: pointer;
  display: flex;
  gap: 12px;
  text-decoration: none;
  color: var(--black);

  padding: 12px 16px;

  transition: 0.125s background-color;

  &__Avatar {
    width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 100px;

    &_system {
      padding: 5px;
      background-color: var(--white);
    }
  }

  &__Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  &__Header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 4px;
  }

  &__Name {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    @include fonts.inter_16_medium;
  }

  &__Time {
    color: var(--grey);
    @include fonts.inter_14_regular;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__Preview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

  &__LastMessage {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    color: var(--grey);
    @include fonts.inter_14_regular;
  }

  &__UnreadCount {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 6px;
    height: 22px;
    min-width: 22px;
    border-radius: 100px;

    color: var(--white);
    border: 1px solid #8484f6;
    background-color: #8484f6;
    transition: 0.125s border;
    @include fonts.inter_14_medium;
  }

  &__Group {
    height: 16px;
    width: 16px;
  }

  &__Pin {
    min-width: 14px;
    width: 14px;
    height: 14px;
    color: #656874;
  }

  &:hover {
    background-color: #e9e9e9;
  }

  &_system {
    background-color: #e2e2ff;

    &:hover {
      background-color: #d6d6f3;
    }
  }

  &_active {
    color: var(--white);
    background-color: #0e0ef280;

    &:hover {
      background-color: #0c0cd380;
    }
  }

  &_active &__UnreadCount {
    border: 1px solid var(--white);
  }

  &_active &__LastMessage,
  &_active &__Time,
  &_active &__Pin {
    color: var(--white);
  }
}
