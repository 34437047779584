@use '../../../../app/styles/fonts';

.Chat {
  height: calc(100dvh - 80px);
  max-width: 2240px;
  margin: 0 auto;
  display: flex;
}

.ChatInfo {
  width: 100%;
  max-width: 336px;
  position: relative;

  display: none;
  flex-direction: column;
  padding: 10px 10px;
  background-color: var(--white);
  border-left: 1px solid #d1d2d6;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: var(--grey);
  }

  &_open {
    display: flex;
  }

  &__Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-bottom: 24px;
  }

  &__Name {
    text-align: center;
    @include fonts.inter_16_medium;
  }

  &__Status {
    color: var(--grey);
    @include fonts.inter_14_regular;
  }

  &__Avatar {
    align-self: center;
    width: 120px;
    height: 120px;
    margin-top: 68px;
    margin-bottom: 20px;
  }

  &__Content {
    display: flex;
    flex-direction: column;
  }

  &__Back,
  &__Close {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.125s background-color;
    cursor: pointer;

    position: absolute;
    top: 10px;

    border: 0;
    width: 44px;
    height: 44px;
    background-color: unset;
    border-radius: 100px;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  &__Close {
    right: 10px;
  }

  &__Back {
    left: 10px;
  }

  &__Buttons {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    gap: 4px;
  }

  &__Button {
    @include fonts.inter_16_regular;
    height: 46px;
    gap: 4px;

    & > svg {
      width: 18px;
      height: 18px;
    }
  }
}

.ChatInfoField {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 12px;
  transition: 0.125s background-color;

  svg {
    width: 24px;
    height: 24px;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__Label {
    @include fonts.inter_12_regular;
    color: #747785;
  }

  &__Text {
    @include fonts.inter_16_medium;
  }

  &:hover {
    background-color: #e9e9e9;
  }
}

.GroupUsers {
  display: flex;
  flex-direction: column;
}

.GroupUsersItem {
  cursor: pointer;
  @include fonts.inter_16_medium;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px;
  border-radius: 12px;
  transition: 0.125s background-color;

  &__Avatar {
    width: 42px;
    height: 42px;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__Activity {
    color: var(--grey);
    @include fonts.inter_14_regular;
  }

  &_assistant > div {
    background-color: unset;
  }

  &:hover {
    background-color: #e9e9e9;
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
}
